@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet";

.footer {
  background-color: $darkGray;
  color: white;
  font-family: "Gilroy", sans-serif;

  padding: 30px 0px;

  a{
    all: unset;
  }

  &__offer {
    // all: unset;
    display: block;
    font-weight: 600;
    margin-top: 12px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }


  &__container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;

    gap: 20px;
  }

  &__leftSide{
    display: flex;
    gap: 20px;
  }

  &__rightSide{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    align-self: center;
  }

  &__mediaContainer {    
    display: flex;
    gap: 15px;
    align-items: center;

    a{
      all: unset;
      width: 40px;
    }
  }

  &__mediaIcon {
    width: 40px;
    &:hover {
      cursor: pointer;
    }
  }

  &__phone {
    display: flex;
    gap: 5px;
  }

  &__phoneIcon{
    width: 20px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__phoneText{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;

    align-self: center;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__btn {
    padding: 20px;
    background-color: $beige;
    color: $darkGray;
    border: 2px solid $beige;

    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
    user-select: none;
    align-self: center;
    justify-self: center;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      color: $beige;
      border: 2px solid $beige;
      box-sizing: border-box;
      transition: .2s;
    }
  }
}

@media(max-width:1320px){

  .footer{
    padding: 30px 0;

    &__rightSide{
      justify-self: end;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
  
    &__container {
      gap: 40px;
    }
  
    &__phone{
      justify-self: end;
    }
  
    &__btn {
      grid-row: 2;
      grid-column: 1/3;
  
      justify-self: auto;
    }
  }

}

@media(max-width:770px){

  .footer{
    padding: 30px 0;
  
    &__container {
      gap: 60px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &__leftSide {
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      width: 40%;
    }

    &__text {
      font-size: 27px;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;

    }

    &__rightSide{
      justify-self: center;
      justify-items: center;
      gap: 40px;
      grid-template-columns: 1fr;
    }

    &__mediaIcon {
      width: 60px;
    }
  
    &__phone{
      justify-self: center;
      gap: 20px;
    }

    &__phoneIcon{
      width: 40px;
    }

    &__phoneText{
      font-size: 34px;
      line-height: 45px;
      text-align: right;
    }
  
    &__btn {
      grid-row: auto;
      grid-column: auto;

      font-size: 27px;
      line-height: 45px;
    }

    &__mediaContainer {
      gap: 30px;
    }
  
    &__phone {
      display: flex;
      gap: 5px;
    }
  }

}

@media(max-width:500px){

  .footer{
    padding: 30px 0;
  
    &__container {
      gap: 60px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &__leftSide {
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      width: 150px;
    }

    &__text {
      font-size: 16px;
      line-height: 19px;
    }

    &__rightSide{
      justify-self: center;
      justify-items: center;
      gap: 40px;
      grid-template-columns: 1fr;
    }

    &__mediaIcon {
      width: 60px;
    }
  
    &__phone{
      justify-self: center;
      gap: 20px;
    }

    &__phoneIcon{
      width: 40px;
    }

    &__phoneText{
      font-size: 20px;
      line-height: 27px;
    }
  
    &__btn {
      grid-row: auto;
      grid-column: auto;

      font-size: 16px;
      line-height: 27px;
    }

    &__mediaContainer {
      gap: 30px;

    }
  
    &__phone {
      display: flex;
      gap: 5px;
    }
  }

}
