.header {
  display: flex;
  height: 136px;
  align-items: center;
  justify-content: space-between;
}
.header-firstblock {
  display: flex;
  justify-content: space-evenly;
  width: 55%;
}
.header-secondblock {
  display: flex;
  justify-content: space-evenly;
  width: 45%;
}
.header-logo {
  width: 20%;
  height: auto;
}
.header-navigation {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
}
.header-navigation__item {
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: none;
  color: #212121;
  font-family: Gilroy, sans-serif;
  font-size: 14.72px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.635px;
}
.header-phone {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-phone__number {
  margin-left: 10px;
  color: #212121;
  text-align: right;
  text-decoration: none;
  font-family: Gilroy, sans-serif;
  font-size: 14.72px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.header-phone__number:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .header-navigation {
    display: none;
  }
  .header-firstblock {
    justify-content: flex-start;
  }
  .header-phone {
    position: absolute;
    right: 20%;
    top: 55px;
  }
  .header-logo {
    width: 115px;
    height: auto;
  }
}
@media (max-width: 576px) {
  .header {
    height: 90px;
  }
  .header-logo {
    width: 68px;
    height: auto;
  }
  .header-phone {
    top: 35px;
  }
}/*# sourceMappingURL=header.css.map */