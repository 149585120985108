.mainTeam {
	margin-top: 150px;
}

.mainArticle {
	font-size: 80px;
	font-weight: 600;
	line-height: 80px;
	margin-left: 8px;
	margin-bottom: 30px;
	text-align: center;
	font-family: Rubik, sans-serif;
}

.sliderBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: auto;
}
.sliderBox__image {
	width: 100%;
	height: auto;
	height: 450px;
	object-fit: cover;
}
.sliderBox__article {
	font-size: 29px;
	line-height: 36.8px;
	font-weight: 600;
	margin-top: 20px;
	width: 100%;
	text-align: center;
	font-family: Rubik, sans-serif;
}
.sliderBox__description {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	line-height: 17px;
	font-weight: 500;
	margin-top: 7px;
	font-family: Rubik, sans-serif;
	background: #f2e0d1;
	width: 90%;
	height: 48px;
}
.sliderBox__subtitle {
	margin-top: 17px;
	font-size: 14px;
	line-height: 17px;
	font-weight: 400;
	width: 100%;
	font-family: Gilroy, sans-serif;
	text-align: left;
}
.sliderBox-country {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.sliderBox-country__country {
	font-size: 16.5px;
	line-height: 16.5px;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 0;
}
.sliderBox-country__arrow {
	margin: 0 10px;
}
.sliderBox-country__ukr {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16.5px;
	line-height: 16.5px;
	font-weight: 700;
}

.sliderArrow-next {
	position: absolute;
	top: 35%;
	right: -2%;
	cursor: pointer;
}
.sliderArrow-prev {
	position: absolute;
	top: 35%;
	left: -2%;
	cursor: pointer;
}

.slick-slider {
	margin-bottom: 0px;
	position: relative;
}

.slick-slide > div {
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.slick-list {
	margin: 0 -10px;
}

.slick-dots li.slick-active button:before {
	width: 44px !important;
	height: 44px !important;
	background: linear-gradient(180deg, #19d8a8 0%, #077258 100%);
	content: '';
	border-radius: 50%;
}

.slick-dots li button::before {
	width: 25px !important;
	height: 25px !important;
	background: linear-gradient(180deg, #212121 0%, #0d0d0d 100%);
	content: '';
	border-radius: 50%;
	opacity: 1 !important;
}

.slick-dots {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	bottom: -80px !important;
}
.slick-dots > li {
	width: 25px !important;
	height: 25px !important;
	margin: 0 20px !important;
}
.slick-dots > li.slick-active {
	width: 44px !important;
	height: 44px !important;
}

@media (max-width: 1024px) {
	.mainArticle {
		font-size: 68.07px;
		font-weight: 700;
		line-height: 84.26px;
		margin-left: 8px;
		margin-bottom: 60px;
	}
	.sliderBox__article {
		font-size: 51.6px;
		line-height: 51.6px;
	}
	.sliderBox__description {
		font-size: 25.8px;
		line-height: 30.96px;
	}
	.sliderBox-country__country {
		font-size: 25.8px;
		line-height: 25.8px;
	}
	.sliderBox-country__ukr {
		font-size: 25.8px;
		line-height: 25.8px;
	}
	.sliderArrow-next {
		right: -3%;
	}
	.sliderArrow-prev {
		left: -3%;
	}
}
@media (max-width: 600px) {
	.mainArticle {
		font-size: 40px;
		font-weight: 700;
		line-height: 49.52px;
		margin-left: 8px;
		margin-bottom: 40px;
	}
	.sliderBox__article {
		font-size: 24px;
		line-height: 24px;
	}
	.sliderBox__description {
		font-size: 20px;
		line-height: 20px;
	}
	.sliderBox-country__country {
		font-size: 16px;
		line-height: 16px;
	}
	.sliderBox-country__ukr {
		font-size: 16px;
		line-height: 16px;
	}
	.sliderArrow-next {
		right: -3%;
	}
	.sliderArrow-prev {
		left: -3%;
	}
	.slick-dots li.slick-active button:before {
		width: 31.58px !important;
		height: 31.58px !important;
		background: linear-gradient(180deg, #19d8a8 0%, #077258 100%);
		content: '';
		border-radius: 50%;
	}
	.slick-dots li button::before {
		width: 17.76px !important;
		height: 17.76px !important;
		background: linear-gradient(180deg, #212121 0%, #0d0d0d 100%);
		content: '';
		border-radius: 50%;
		opacity: 1 !important;
	}
	.slick-dots {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		bottom: -80px !important;
	}
	.slick-dots > li {
		width: 17.76px !important;
		height: 17.76px !important;
		margin: 0 10px !important;
	}
	.slick-dots > li.slick-active {
		width: 31.58px !important;
		height: 31.58px !important;
	}
}
.slick-slider {
	margin-bottom: 0px !important;
}

/*# sourceMappingURL=OurCase.css.map */
