.tutoring-form {
  width: 610px;
}

.form-tutoring {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #F2E0D1;
}
.form-title {
  text-align: center;
  color: #FAFAFA;
  text-align: center;
  font-family: Rubik;
  font-size: 23.995px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  background-color: #212121;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-title__decorate {
  color: #F2E0D1;
}
.form-control {
  width: 181px !important;
  height: 50px !important;
  border-radius: 0px !important;
  flex-shrink: 0;
  margin-right: 20px !important;
  margin: 0 !important;
  border: 2px solid #212121 !important;
}

.react-tel-input {
  margin-left: 20px;
  width: 183px !important;
  height: 50px !important;
}

.flag-dropdown {
  top: 6px !important;
  left: 2px !important;
  background-color: inherit !important;
}

.flag-dropdown {
  border: inherit !important;
  width: 30% !important;
  height: 80%;
}

.send-form {
  width: 181px;
  height: 50px;
  font-size: 15px;
  border: 2px solid black;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
  background: #212121;
  color: #FAFAFA;
}
.send-form:hover {
  background-color: transparent;
  border: 2px solid black;
  box-sizing: border-box;
  color: black;
  transition: 0.2s;
}

.name {
  border: none;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 180px;
  height: 50px;
}

@media (max-width: 1024px) {
  .tutoring {
    margin-bottom: 70px;
  }
  .tutoring-form {
    display: none;
  }
  .tutoring-form__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: auto;
    margin-bottom: 100px;
  }
  .react-tel-input {
    height: 130px !important;
    width: 80% !important;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .form-title {
    height: 150px;
    width: 100%;
    color: #FAFAFA;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 40.336px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-control {
    height: 130px !important;
    width: 100% !important;
  }
  .name {
    margin-left: 0;
    margin-right: 0;
    height: 130px;
    width: 80%;
    margin-bottom: 20px;
  }
  .send-form {
    height: 130px;
    width: 80%;
    color: #FAFAFA;
    font-family: Gilroy, sans-serif;
    font-size: 33.613px;
    font-style: normal;
    font-weight: 700;
    line-height: 57.446px;
  }
}
@media screen and (max-width: 576px) {
  .form-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90%;
    height: 91px;
  }
  .form-control {
    height: 78px !important;
  }
  .form-tutoring {
    width: 90%;
  }
  .name {
    height: 78px;
  }
  .react-tel-input {
    height: 78px !important;
    width: 80% !important;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .send-form {
    height: 79px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 34.181px;
  }
}/*# sourceMappingURL=form.css.map */