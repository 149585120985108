.btn:hover {
	background: #d4d4d4;
}

.btn.active {
	background: #212121;
	color: #fff;
	border: none;
}

.submit {
	// background: #000;
	color: #fff;
}

.submit:disabled {
	// background: #444;
	// cursor: not-allowed;
}
.lesson-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fafafa;
	color: #212121;
	font-size: 14.72px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	border-radius: 0px;
	height: 38px;
	transition: all 0.3s ease;
	width: 100%;
}

.lesson-item .price {
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

.services-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	@media (max-width: 788px) {
		grid-template-columns: 1fr;
	}
	gap: 11px;
	&__block {
		padding: 35px 40px 30px;
		margin-bottom: 16px;
		@media (max-width: 788px) {
			padding: 20px;
		}
		& h3 {
			color: #212121;
			text-align: center;
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 24px;
		}
		& button {
			background: #fafafa;
			color: #212121;
			font-size: 14.72px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%;
			border-radius: 0px;
			min-height: 40px;
			padding: 2px 4px;
			transition: all 0.3s ease;
			width: 100%;
			@media (max-width: 788px) {
				font-size: 14.72px;
			}
			&:active {
				border: none;
				background: transparent;
				border: transparent;
			}
			&:disabled {
				opacity: 0.8;
				background: #fafafa;
				border: none;
				transition: all 0.3s ease;
			}
		}
		&.service-type {
			background: #f9e9ee;
		}
		&.subject-selection {
			background: #efe1d3;
		}
		&.lesson-selection {
			background: #c3dfe1;
		}
	}
	&__flex {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}
body .App .services-selection__btn {
	background: #212121;
	padding: 17.432px 46.485px;
	color: #fff;
	text-align: center;
	font-size: 27.891px;
	font-weight: 700;
	line-height: 159.146%;
	width: 100%;
	@media (max-width: 788px) {
		font-size: 14.72px;
	}
	// opacity: 1;
}
