.services{

  &__title{
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 80px;
    text-align: center;
  }

  &__subjects{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media(max-width: 1400px) {
  .services{
    &__title{
      font-size: 59px;
      line-height: 59px;
      margin-bottom: 50px;
    }
  }
}

@media(max-width: 990px){
  .services{
    &__title{
      font-size: 67px;
      line-height: 59px;

    }
  
    &__subjects{
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}

@media (max-width: 500px){
  .services{
    &__title{
      font-size: 45px;
      line-height: 35px;
    }
  }
}