@use '../../UI/colors.scss' as *;
@import '../../fonts/stylesheet.css';

.advantages {
	font-family: 'Gilroy', sans-serif;
	&__title {
		font-size: 60px;
		font-weight: 700;
		line-height: 74px;
		letter-spacing: 0em;
		text-align: center;
		margin-bottom: 40px;
	}

	&__info {
		margin-top: 50px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 150px;
	}

	&__block {
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__subTitle {
		font-size: 37px;
		font-weight: 700;
		line-height: 46px;
		letter-spacing: 0em;
		text-align: left;
		padding: 10px;
		margin-bottom: 15px;
		width: 100%;

		align-self: flex-start;

		&_beige {
			background-color: $beige;
		}
		&_pink {
			background-color: $pink;
		}
	}

	&__text {
		font-size: 18px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: 0em;
		text-align: left;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 60px;
		margin-top: 24px;
		@media (max-width: 1024px) {
			grid-template-columns: 1fr;
		}
		& li {
			list-style: none;
			line-height: 1.4;
			& + li {
				margin-top: 10px;
			}
		}
	}

	&__footer {
		background-color: $darkGray;
		color: $beige;
		font-size: 13px;
		font-weight: 700;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: left;
		padding: 10px;
		margin-top: 15px;
	}
}

.roadMap {
	position: relative;
	&__map {
		width: 100%;
		position: absolute;
		z-index: 1;
	}

	&__grid {
		z-index: 3;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		row-gap: 40px;
	}

	&__advantage {
		z-index: 3;
		display: flex;
		align-items: center;
		gap: 15px;
	}

	&__text {
		font-size: 18px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: 0em;
		text-align: left;
	}
}

@media (max-width: 1400px) {
	.advantages__block {
		justify-content: flex-start;
	}
	.roadMap__number {
		width: 20%;
	}
}
@media (max-width: 1200px) {
	.advantages__subTitle {
		font-size: 30px;
		line-height: 40px;
	}
}

@media (max-width: 995px) {
	.advantages {
		font-family: 'Gilroy', sans-serif;
		&__title {
			margin-bottom: 80px;
			font-family: 'Rubik', sans-serif;
			font-size: 59px;
			font-weight: 600;
			line-height: 59px;
		}

		&__info {
			margin-top: 50px;
			grid-template-columns: 1fr;
			gap: 30px;
		}

		&__block {
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__text {
			font-size: 24px;
			line-height: 28px;
		}

		&__footer {
			font-size: 24px;
			line-height: 29px;
		}
	}

	.roadMap {
		position: relative;
		&__map {
			display: none;
		}

		&__grid {
			z-index: 3;
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: 40px;
		}

		&__text {
			font-size: 24px;
			line-height: 28px;
		}
	}

	.roadMap__number {
		width: 25%;
	}
}

@media (max-width: 500px) {
	.advantages {
		font-family: 'Gilroy', sans-serif;
		&__title {
			font-size: 35px;
			font-weight: 600;
			line-height: 35px;
			margin-bottom: 30px;
		}

		&__block {
			padding: 0px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__text {
			font-size: 14px;
			line-height: 17px;
		}

		&__subTitle {
			font-size: 23px;
			line-height: 28px;
		}

		&__footer {
			font-size: 14px;
			line-height: 17px;
		}
	}

	.roadMap {
		position: relative;
		&__map {
			display: none;
		}

		&__grid {
			z-index: 3;
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: 40px;
		}

		&__text {
			font-size: 14px;
			line-height: 17px;
		}

		&__number {
			width: 25%;
		}
	}
}
