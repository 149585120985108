.tutoring
  display: flex
  background-image: url("../../images and icons/images/tutoringbg_web.webp")
  background-size: cover
  background-repeat: no-repeat
  background-position: right
  margin-bottom: 160px
  padding-bottom: 40px
  justify-content: center
  align-items: center
  @media (min-height: 890px)
    height: 100%
  @media (max-width: 768px)
    padding-bottom: 0

  &-wrapper
    @media (min-height: 890px)
      height: calc(100vh - 136px)

  &-gradient
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%)
    width: 50%
    position: absolute

  &-center
    display: flex
    flex-direction: column
    z-index: 8

    &__title
      color: #FAFAFA
      font-family: Rubik, sans-serif
      font-size: 60.993px
      font-style: normal
      font-weight: 600
      line-height: 60.993px
      margin-bottom: 1%
      margin-top: 6%

    &__text
      color: #FAFAFA
      font-family: Gilroy, sans-serif
      font-size: 18.298px
      font-style: normal
      font-weight: 400
      line-height: normal
      width: 35%
      margin-bottom: 32px
      @media (max-width: 768px)
        margin-bottom: 20px !important

    &__advantages
      display: flex
      margin-bottom: 32px
      width: 50%

    &__advantage
      width: 33%
      margin-right: 40px
      white-space: nowrap

      &-title
        margin-top: 15px
        color: #F2E0D1
        font-family: Rubik, sans-serif
        font-size: 33.126px
        line-height: 27.605px
        font-style: normal
        font-weight: 600
        z-index: 1

      &-text
        color: #FAFAFA
        font-family: Gilroy, sans-serif
        font-size: 11.042px
        font-style: normal
        font-weight: 600
        line-height: normal

@media (min-width: 1024px)
  .tutoring-form__mobile
    display: none

@media (max-width: 1024px)
  .form
    &-tutoring
      width: 100%
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      background-color: #F2E0D1

  .sc-gsDKeI
    position: absolute !important

  .tutoring
    background-image: url("../../images and icons/images/tutoringbg_mob.webp")
    background-position: center

    &-gradient
      width: 100%
      background: none

    &-center
      &__title
        color: #FFF
        text-align: center
        font-family: Rubik, sans-serif
        font-style: normal
        font-weight: 600
        line-height: 58.823px

      &__text
        width: 100%
        color: #FAFAFA
        text-align: center
        margin: auto
        margin-top: 370px
        font-family: Gilroy, sans-serif
        font-size: 26.89px
        font-style: normal
        font-weight: 400
        line-height: normal

      &__advantages
        width: 100%
        align-items: flex-start
        margin: auto
        justify-content: center

      &__advantage
        margin-right: 5px
        margin-left: 5px
        text-align: flex-start
        padding-left: 2%


        &-title
          color: #F2E0D1
          font-family: Rubik, sans-serif
          font-size: 43.802px
          font-style: normal
          font-weight: 600
          line-height: 36.502px

        &-text
          color: #FAFAFA
          font-family: Gilroy, sans-serif
          font-size: 14.601px
          font-style: normal
          font-weight: 600
          line-height: normal

      &__advantage:nth-child(2)
        padding-left: 5%

      &__advantage:last-child
        padding-left: 5%

@media (max-width: 576px)
  .form
    &-tutoring
      width: 100%
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      background-color: #F2E0D1

  .sc-gsDKeI
    position: absolute !important

  .tutoring
    background-image: url("../../images and icons/images/tutoringbg_mob.webp")
    background-position: center

    &-gradient
      width: 100%
      background: none

    &-center
      &__title
        font-size: 32px
        font-style: normal
        font-weight: 600
        line-height: 35px

      &__text
        width: 100%
        color: #FAFAFA
        text-align: center
        margin: auto
        margin-top: 470px
        font-size: 16px
        font-style: normal
        font-weight: 400
        line-height: normal

      &__advantages
        width: 100%
        align-items: flex-start


      &__advantage
        margin-right: 5px
        margin-left: 5px
        text-align: flex-start
        padding-left: 2%


        &-title
          color: #F2E0D1
          font-family: Rubik, sans-serif
          font-size: 43.802px
          font-style: normal
          font-weight: 600
          line-height: 36.502px

        &-text
          color: #FAFAFA
          font-family: Gilroy, sans-serif
          font-size: 14.601px
          font-style: normal
          font-weight: 600
          line-height: normal

      &__advantage:nth-child(2)
        padding-left: 5%

@media screen and (max-width: 576px)
  .tutoring
    height: 589px
    background-image: url("../../images and icons/images/tutoringbg_mob(576).webp")
    background-size: cover


    &-gradient
      height: 589px
      width: 100%
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%)

    &-center
      &__advantage:nth-child(2)
        margin-top: 0

      &__text
        width: 90%
        margin-top: 190px
        margin-bottom: 0px

      &__advantages
        margin: auto
        justify-content: center
        width: auto

      &__advantage
        width: auto

        &-title
          font-size: 26.063px
          font-style: normal
          font-weight: 600
          line-height: 21.719px

        &-text
          font-size: 8.688px
          font-style: normal
          font-weight: 600
          line-height: normal

        &-img
          width: 25px
          height: 25px



