.thank-you {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 16px;
	& h1 {
		font-size: 92px;
		margin-bottom: 24px;
		font-family: 'Rubik', sans-serif;
		@media (max-width: 1024px) {
			font-size: 82px;
		}
		@media (max-width: 768px) {
			font-size: 40px;
		}
	}
	& p {
		font-size: 32px;
		margin-bottom: 24px;
		font-family: 'Gilroy', sans-serif;
		@media (max-width: 768px) {
			font-size: 24px;
		}
	}
	& .donate__btn {
		width: max-content;
		margin: 0 auto;
		font-family: 'Gilroy', sans-serif;
		&:hover {
			opacity: 0.7;
			background: rgb(242, 224, 209);
			color: #000;
		}
	}
}
