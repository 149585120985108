.header-navigation {
	a {
		&:hover {
			font-weight: bold;
		}
	}
}
.margin-step__item h4 {
	font-size: 18px; // change
}
.margin-step__item img {
	-o-object-fit: none; // deleted this
}
@media (max-width: 1024px) {
	// change width
	.margin-choose__top {
		grid-template-columns: 1fr;
		margin-top: 80px;
	}
	.margin-choose__top {
		gap: 24px; // change gap
	}
}

@media (max-width: 1200px) {
	.margin-choose__wrapper {
		padding-left: 40px;
	}
}
