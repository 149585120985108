.marafon {
	padding-bottom: 124px;
	&-subtitle {
		background: #f2e0d1;
		padding: 10px 30px;
		color: #212121;
		text-align: center;
		font-size: 17.664px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		font-family: 'Gilroy', sans-serif;
		display: block;
		width: max-content;
		margin: 0 auto 30px;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	& .roadMap__grid {
		gap: 78px;
		@media (max-width: 1200px) {
			gap: 24px;
		}
		@media (max-width: 768px) {
			grid-template-columns: 1fr;
			.roadMap__number {
				width: 16%;
			}
		}
	}
	& .roadMap__map {
		object-fit: contain;
		height: 235px;
		width: 110%;
		transform: translate(-35px, -58px);
		@media (max-width: 1400px) {
			display: none;
		}
	}
	&-text {
		color: #212121;
		font-family: 'Gilroy', sans-serif;
		text-align: center;
		font-weight: 400;
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 83px;
		@media (max-width: 768px) {
			margin-bottom: 42px;
			font-size: 18px;
		}
	}
	&-program__title {
		color: #212121;
		font-family: 'Glroy', sans-serif;
		text-align: center;
		font-size: 44.161px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-bottom: 80px;
		@media (max-width: 768px) {
			margin-bottom: 32px;
			font-size: 32px;
		}
	}
	&-info {
		margin-top: 90px;
		@media (max-width: 768px) {
			margin-top: 60px;
		}
		&__top {
			padding: 10px 30px;
			background: #f2e0d1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 24px;
			@media (max-width: 768px) {
				flex-direction: column;
				align-items: flex-start;
				gap: 16px;
			}
			&-cost {
				color: #212121;
				text-align: center;
				font-size: 22px;
				font-family: 'Glroy', sans-serif;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				@media (max-width: 768px) {
					text-align: left;
					font-size: 18px;
				}
			}
			&-text {
				color: #212121;
				font-family: 'Glroy', sans-serif;
				font-size: 17.664px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		&__content {
			background: #fce8ee;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			gap: 24px;
			padding: 32px;
			@media (max-width: 768px) {
				padding: 16px 30px;
			}
			& .form-title {
				margin-bottom: 20px;
				width: 100%;
				@media (max-width: 768px) {
					font-size: 18px;
					height: auto;
					padding: 16px;
				}
			}
			& p {
				color: #212121;
				font-size: 26.496px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				font-family: 'Glroy', sans-serif;
				& a {
					color: #212121;
					font-weight: 600;
				}
				@media (max-width: 768px) {
					font-size: 18px;
				}
			}
		}
	}
	&-bottom {
		color: #212121;
		font-family: 'Glroy', sans-serif;
		font-size: 17.664px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		& ul {
			display: flex;
			align-items: center;
			list-style: none;
			gap: 8px;

			& li {
				background: #212121;
				width: 29.44px;
				height: 29.44px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				transition: all 0.3s ease;
				&:hover {
					cursor: pointer;
					transition: all 0.3s ease;
					transform: scale(1.1);
				}
				&.instagram-marafon svg {
					transform: translate(1px, -1px);
				}
				&.tiktok-marafon svg {
					transform: translate(1px, -1px);
				}
				&.facebook-marafon svg {
					transform: translate(-0.5px, -1px);
				}
			}
		}
	}
}

.marathonForm {
	display: flex;
	flex-direction: column;
	justify-items: space-between;

	&__title {
		font-family: 'Rubik', sans-serif;
		font-size: 80px;
		font-weight: 600;
		line-height: 80px;
		letter-spacing: 0em;
		text-align: center;

		margin-bottom: 20px;
	}

	&__subTitle {
		font-family: 'Gilroy', sans-serif;
		font-size: 24px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: center;

		padding: 10px 20px;

		background-color: rgba(242, 224, 209, 1);
		display: inline-block;
		align-self: center;
		margin-bottom: 40px;
	}

	&__form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 50px;
	}

	&__input {
		padding: 10px;
		background-color: rgba(250, 250, 250, 1);
		color: rgba(33, 33, 33, 1);
		font-family: 'Gilroy', sans-serif;
		font-size: 24px;
		font-weight: 400;
		line-height: 33px;
		letter-spacing: 0em;
		text-align: left;
		border: 2px solid rgba(33, 33, 33, 1);
		position: relative;

		&::placeholder {
			color: rgba(33, 33, 33, 1);
		}
	}

	&__btn {
		all: unset;
		padding: 20px;
		background-color: rgba(33, 33, 33, 1);
		color: white;
		border: 2px solid rgba(33, 33, 33, 1);
		box-sizing: border-box;

		font-family: 'Gilroy', sans-serif;
		font-size: 20px;
		font-weight: 700;
		line-height: 25px;
		letter-spacing: 0em;
		text-align: center;

		user-select: none;

		&:hover {
			cursor: pointer;
			background-color: transparent;
			color: rgba(33, 33, 33, 1);
			border: 2px solid rgba(33, 33, 33, 1);
			box-sizing: border-box;
			transition: 0.2s;
		}
	}

	.react-tel-input {
		width: auto !important;
		height: auto !important;
		margin: 0 !important;

		font-family: 'Gilroy', sans-serif !important;
		font-size: 24px !important;
		font-weight: 400 !important;
		line-height: 33px !important;

		.selected-flag {
			border: none !important;
		}

		input {
			width: 100% !important;
			height: 100% !important;
			padding: 10px !important;
			padding-left: 50px !important;
			background-color: rgba(250, 250, 250, 1);
			color: rgba(33, 33, 33, 1) !important;
			font-family: 'Gilroy', sans-serif !important;
			font-size: 27px !important;
			font-weight: 400 !important;
			line-height: 33px !important;
			letter-spacing: 0em;
			text-align: left;
			border: 2px solid rgba(33, 33, 33, 1) !important;
			position: relative;

			&::placeholder {
				color: rgba(33, 33, 33, 1);
			}
		}
	}

	#forSafari {
		.marathonForm__form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 80% 80%;
			gap: 20px;
		}
	}
}

@media (max-width: 1200px) {
	.marathonForm {
		&__title {
			font-size: 59px;
			line-height: 59px;
		}

		&__subTitle {
			font-size: 18px;
			line-height: 21px;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 50px;
		}

		&__input {
			font-size: 27px;
			line-height: 32px;
		}

		&__btn {
			font-size: 27px;
			line-height: 33px;
		}
	}
}

@media (max-width: 991px) {
	.marathonForm {
		&__title {
			font-size: 67px;
			line-height: 59px;
		}

		&__subTitle {
			font-size: 23px;
			line-height: 28px;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;
		}

		&__input {
			font-size: 34px;
			line-height: 40px;
			padding: 20px;
		}

		.react-tel-input {
			input {
				font-size: 34px !important;
				line-height: 40px !important;
				padding: 20px !important;
				padding-left: 40px !important;
			}
		}

		&__btn {
			font-size: 27px;
			line-height: 33px;
		}
	}
}

@media (max-width: 500px) {
	.marathonForm {
		&__title {
			font-size: 40px;
			line-height: 35px;
		}

		&__subTitle {
			font-size: 14px;
			line-height: 17px;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			gap: 20px;
		}

		&__input {
			height: auto;
			font-size: 20px;
			line-height: 24px;
		}

		&__btn {
			font-size: 20px;
			line-height: 34px;
		}

		.react-tel-input {
			input {
				height: auto !important;
				font-size: 20px !important;
				line-height: 24px !important;
				padding-left: 60px !important;
			}
		}
	}
}
.clubForm {
	display: flex;
	flex-direction: column;
	justify-items: space-between;

	&__title {
		font-family: 'Rubik', sans-serif;
		font-size: 80px;
		font-weight: 600;
		line-height: 80px;
		letter-spacing: 0em;
		text-align: center;

		margin-bottom: 20px;
	}

	&__subTitle {
		font-family: 'Gilroy', sans-serif;
		font-size: 24px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: center;

		padding: 10px 20px;

		background-color: rgba(242, 224, 209, 1);
		display: inline-block;
		align-self: center;
		margin-bottom: 40px;
	}

	&__form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 50px;
	}

	&__input {
		padding: 10px;
		background-color: rgba(250, 250, 250, 1);
		color: rgba(33, 33, 33, 1);
		font-family: 'Gilroy', sans-serif;
		font-size: 24px;
		font-weight: 400;
		line-height: 33px;
		letter-spacing: 0em;
		text-align: left;
		border: 2px solid rgba(33, 33, 33, 1);
		position: relative;

		&::placeholder {
			color: rgba(33, 33, 33, 1);
		}
	}

	&__btn {
		all: unset;
		padding: 20px;
		background-color: rgba(33, 33, 33, 1);
		color: white;
		border: 2px solid rgba(33, 33, 33, 1);
		box-sizing: border-box;

		font-family: 'Gilroy', sans-serif;
		font-size: 20px;
		font-weight: 700;
		line-height: 25px;
		letter-spacing: 0em;
		text-align: center;

		user-select: none;

		&:hover {
			cursor: pointer;
			background-color: transparent;
			color: rgba(33, 33, 33, 1);
			border: 2px solid rgba(33, 33, 33, 1);
			box-sizing: border-box;
			transition: 0.2s;
		}
	}

	.react-tel-input {
		width: auto !important;
		height: auto !important;
		margin: 0 !important;

		font-family: 'Gilroy', sans-serif !important;
		font-size: 24px !important;
		font-weight: 400 !important;
		line-height: 33px !important;

		.selected-flag {
			border: none !important;
		}

		input {
			width: 100% !important;
			height: 100% !important;
			padding: 10px !important;
			padding-left: 50px !important;
			background-color: rgba(250, 250, 250, 1);
			color: rgba(33, 33, 33, 1) !important;
			font-family: 'Gilroy', sans-serif !important;
			font-size: 27px !important;
			font-weight: 400 !important;
			line-height: 33px !important;
			letter-spacing: 0em;
			text-align: left;
			border: 2px solid rgba(33, 33, 33, 1) !important;
			position: relative;

			&::placeholder {
				color: rgba(33, 33, 33, 1);
			}
		}
	}
	.react-tel-input .flag-dropdown {
		border: 1px solid #fff !important;
	}
	#forSafari {
		.clubForm__form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 80% 80%;
			gap: 20px;
		}
	}
}

@media (max-width: 1200px) {
	.clubForm {
		&__title {
			font-size: 59px;
			line-height: 59px;
		}

		&__subTitle {
			font-size: 18px;
			line-height: 21px;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 50px;
		}

		&__input {
			font-size: 27px;
			line-height: 32px;
		}

		&__btn {
			font-size: 27px;
			line-height: 33px;
		}
	}
}

@media (max-width: 991px) {
	.clubForm {
		&__title {
			font-size: 67px;
			line-height: 59px;
		}

		&__subTitle {
			font-size: 23px;
			line-height: 28px;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;
		}

		&__input {
			font-size: 34px;
			line-height: 40px;
			padding: 20px;
		}

		.react-tel-input {
			input {
				font-size: 34px !important;
				line-height: 40px !important;
				padding: 20px !important;
				padding-left: 40px !important;
			}
		}

		&__btn {
			font-size: 27px;
			line-height: 33px;
		}
	}
}

@media (max-width: 500px) {
	.clubForm {
		&__title {
			font-size: 40px;
			line-height: 35px;
		}

		&__subTitle {
			font-size: 14px;
			line-height: 17px;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			gap: 20px;
		}

		&__input {
			height: auto;
			font-size: 20px;
			line-height: 24px;
		}

		&__btn {
			font-size: 20px;
			line-height: 34px;
		}

		.react-tel-input {
			input {
				height: auto !important;
				font-size: 20px !important;
				line-height: 24px !important;
				padding-left: 60px !important;
			}
		}
	}
}
