.tutoring {
  display: flex;
  background-image: url("../../images and icons/images/tutoringbg_web.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  margin-bottom: 160px;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
}
@media (min-height: 890px) {
  .tutoring {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .tutoring {
    padding-bottom: 0;
  }
}
@media (min-height: 890px) {
  .tutoring-wrapper {
    height: calc(100vh - 136px);
  }
}
.tutoring-gradient {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  width: 50%;
  position: absolute;
}
.tutoring-center {
  display: flex;
  flex-direction: column;
  z-index: 8;
}
.tutoring-center__title {
  color: #FAFAFA;
  font-family: Rubik, sans-serif;
  font-size: 60.993px;
  font-style: normal;
  font-weight: 600;
  line-height: 60.993px;
  margin-bottom: 1%;
  margin-top: 6%;
}
.tutoring-center__text {
  color: #FAFAFA;
  font-family: Gilroy, sans-serif;
  font-size: 18.298px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 35%;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .tutoring-center__text {
    margin-bottom: 20px !important;
  }
}
.tutoring-center__advantages {
  display: flex;
  margin-bottom: 32px;
  width: 50%;
}
.tutoring-center__advantage {
  width: 33%;
  margin-right: 40px;
  white-space: nowrap;
}
.tutoring-center__advantage-title {
  margin-top: 15px;
  color: #F2E0D1;
  font-family: Rubik, sans-serif;
  font-size: 33.126px;
  line-height: 27.605px;
  font-style: normal;
  font-weight: 600;
  z-index: 1;
}
.tutoring-center__advantage-text {
  color: #FAFAFA;
  font-family: Gilroy, sans-serif;
  font-size: 11.042px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (min-width: 1024px) {
  .tutoring-form__mobile {
    display: none;
  }
}
@media (max-width: 1024px) {
  .form-tutoring {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F2E0D1;
  }
  .sc-gsDKeI {
    position: absolute !important;
  }
  .tutoring {
    background-image: url("../../images and icons/images/tutoringbg_mob.webp");
    background-position: center;
  }
  .tutoring-gradient {
    width: 100%;
    background: none;
  }
  .tutoring-center__title {
    color: #FFF;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 58.823px;
  }
  .tutoring-center__text {
    width: 100%;
    color: #FAFAFA;
    text-align: center;
    margin: auto;
    margin-top: 370px;
    font-family: Gilroy, sans-serif;
    font-size: 26.89px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tutoring-center__advantages {
    width: 100%;
    align-items: flex-start;
    margin: auto;
    justify-content: center;
  }
  .tutoring-center__advantage {
    margin-right: 5px;
    margin-left: 5px;
    text-align: flex-start;
    padding-left: 2%;
  }
  .tutoring-center__advantage-title {
    color: #F2E0D1;
    font-family: Rubik, sans-serif;
    font-size: 43.802px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.502px;
  }
  .tutoring-center__advantage-text {
    color: #FAFAFA;
    font-family: Gilroy, sans-serif;
    font-size: 14.601px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .tutoring-center__advantage:nth-child(2) {
    padding-left: 5%;
  }
  .tutoring-center__advantage:last-child {
    padding-left: 5%;
  }
}
@media (max-width: 576px) {
  .form-tutoring {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F2E0D1;
  }
  .sc-gsDKeI {
    position: absolute !important;
  }
  .tutoring {
    background-image: url("../../images and icons/images/tutoringbg_mob.webp");
    background-position: center;
  }
  .tutoring-gradient {
    width: 100%;
    background: none;
  }
  .tutoring-center__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
  }
  .tutoring-center__text {
    width: 100%;
    color: #FAFAFA;
    text-align: center;
    margin: auto;
    margin-top: 470px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tutoring-center__advantages {
    width: 100%;
    align-items: flex-start;
  }
  .tutoring-center__advantage {
    margin-right: 5px;
    margin-left: 5px;
    text-align: flex-start;
    padding-left: 2%;
  }
  .tutoring-center__advantage-title {
    color: #F2E0D1;
    font-family: Rubik, sans-serif;
    font-size: 43.802px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.502px;
  }
  .tutoring-center__advantage-text {
    color: #FAFAFA;
    font-family: Gilroy, sans-serif;
    font-size: 14.601px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .tutoring-center__advantage:nth-child(2) {
    padding-left: 5%;
  }
}
@media screen and (max-width: 576px) {
  .tutoring {
    height: 589px;
    background-image: url("../../images and icons/images/tutoringbg_mob(576).webp");
    background-size: cover;
  }
  .tutoring-gradient {
    height: 589px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
  }
  .tutoring-center__advantage:nth-child(2) {
    margin-top: 0;
  }
  .tutoring-center__text {
    width: 90%;
    margin-top: 190px;
    margin-bottom: 0px;
  }
  .tutoring-center__advantages {
    margin: auto;
    justify-content: center;
    width: auto;
  }
  .tutoring-center__advantage {
    width: auto;
  }
  .tutoring-center__advantage-title {
    font-size: 26.063px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.719px;
  }
  .tutoring-center__advantage-text {
    font-size: 8.688px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .tutoring-center__advantage-img {
    width: 25px;
    height: 25px;
  }
}/*# sourceMappingURL=tutoringcenter.css.map */