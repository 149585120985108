@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet.css";

.mediaBlock {
  background-color: $pink;
  padding: 40px 0;

  &__container {
    display: flex;
    gap: 50px;
    align-items: center;
  }

  &__title {
    font-family: "Rubik", sans-serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;

    justify-self: flex-start;
  }

  &__icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 35px;
  }

  &__icon {
    width: 90%;
    transition: .2s;

    &:hover{
      cursor: pointer;
      transform: scale(1.1)
    }
  }
}

@media (max-width: 990px){
  .mediaBlock {
    padding: 40px 0;
  
    &__container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
      justify-items: center;
    }
  
    &__title {
      font-size: 67px;
      line-height: 59px;
      text-align: center;
      justify-self: flex-start;
    }
  }
}

@media (max-width: 500px){
  .mediaBlock {
    padding: 40px 0;
  
    &__title {
      font-size: 40px;
      line-height: 35px;
    }
  }
}
