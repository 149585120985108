@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet.css";


.donate{

  background-color: $darkGray;
  padding: 30px 0;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  &__title{
    font-family: "Rubik", sans-serif;
    font-size: 59px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left;
    color: white;

    align-self: center;
  }

  &__btn {
    all: unset;
    display: block;
    color: black;
    background-color: $beige;
    justify-self: flex-start;
    align-self: center;

    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;

    padding: 10px 30px;

    grid-row-start: 2;
    border: 2px solid $beige;
    transition: .2s;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      color: $beige;
    }
  }

  &__svg {
    grid-row: 1/3;
    justify-self: flex-end;
  }

  path{
    transition:.2s ease all;
  }
  svg path:hover{
    fill: $blue !important;
  }
}

@media (max-width: 770px){
  .donate{
    padding: 30px 0;
  
    .container {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  
    &__title{
      font-size: 67px;
      line-height: 59px;
      justify-self: center;
      text-align: center;
    }
  
    &__btn {
      justify-self: center;
      font-size: 23px;
      line-height: 28px;
      grid-row-start: 3;
    }
  
    &__svg {
      grid-row: 2;
      justify-self: center;
    }
  }
}

@media (max-width: 500px){
  .donate{
    padding: 30px 0;

    svg {
      width: 90vw;
    }
  
    .container {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  
    &__title{
      font-size: 40px;
      line-height: 35px;
    }
  
    &__btn {
      font-size: 13px;
      line-height: 17px;
    }
  
    &__svg {
      grid-row: 2;
      justify-self: center;
    }
  }
}