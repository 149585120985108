@use "../../../UI/colors.scss" as *;

#popup__subTitle{
  &_eng{
    background-color: $pink;
  }

  &_ukr{
    background-color: $blue;
  }
}