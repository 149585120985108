@import '../../../UI/colors.scss'
.button
  width: 100%
  height: 44.897px
  flex-shrink: 0
  user-select: none
  cursor: pointer
  background-color: $darkGray
  border: 2px solid $darkGray
  color: #FAFAFA
  font-family: Gilroy, sans-serif
  font-size: 11.776px
  font-style: normal
  font-weight: 700
  line-height: 19.635px
  box-sizing: border-box

  &:hover
    background-color: transparent
    color: $darkGray
    border: 2px solid $darkGray
    box-sizing: border-box
    transition: .2s

@media (max-width: 1200px)

  .button
    display: none
