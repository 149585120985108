@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet.css";

.feedbacks {

  &__title {
    font-family: "Rubik";
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;

    margin-bottom: 30px;
  }

  &__videos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  &__videoContainer{
    position: relative;
  }

  &__video{
    width: 100%;

  }

  &__playBtn{
    position: absolute;
    left: 0; 
    right: 0; 
    top: 0; 
    bottom: 0; 
  
    margin: auto; 
    width: 100px;
  }

  &__footer{
    background-color: $beige;
    width: 100%;
    padding: 50px;

    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  }

  &__text{
    font-family: "Rubik", sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__btn{
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    box-sizing: border-box;
    border: 2px solid $darkGray;

    background-color: $darkGray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    transition: .2s;

    &:hover{
      cursor: pointer;
      background-color: transparent;
      color: $darkGray;
      border: 2px solid $darkGray;
      box-sizing: border-box;
      transition: .2s;
    }
  }

  .slick-slider {
    margin-bottom: 40px;
  }

}

@media (max-width: 1320px){
  .feedbacks {
    
    &__title {
      font-size: 59px;
      line-height: 59px;

      margin-bottom: 30px;
    }
  
    &__videos {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
  
      margin-bottom: 40px;
    }
  
    &__playBtn{
      position: absolute;
      left: 0; 
      right: 0; 
      top: 0; 
      bottom: 0; 
    
      margin: auto; 
      width: 100px;
    }
  
    &__footer{
      background-color: $beige;
      width: 100%;
      padding: 50px;
  
      display: flex;
      justify-content: space-between;
    }
  
    &__text{
      font-size: 22px;
      line-height: 37px;
    }
  
    &__btn{
      font-size: 18px;
      line-height: 28px;
      padding: 10px 20px;
    }
  }
}


@media (max-width: 990px){
  .feedbacks {
    
    &__title {
      margin-bottom: 30px;

      font-size: 67px;
      line-height: 59px;
    }
  
    &__videos {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
  
      margin-bottom: 40px;
    }
  
    &__playBtn{
      position: absolute;
      left: 0; 
      right: 0; 
      top: 0; 
      bottom: 0; 
    
      margin: auto; 
      width: 100px;
    }
  
    &__footer{
      background-color: transparent;
      width: 100%;
      padding: 0;
  
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  
    &__text{
      font-size: 27px;
      line-height: 27px;
      text-align: center;

      margin: 0 90px;
      background-color: $beige;
    }
  
    &__btn{
      padding: 20px 30px;

      font-size: 22px;
      line-height: 30px;

    }
  }
}

@media (max-width: 700px){
  .feedbacks__text{
    margin: 0;
  }
}

@media (max-width: 400px){
  .feedbacks {
    
    &__title {
      margin-bottom: 30px;
      font-size: 40px;
      line-height: 35px;
    }
  
    &__footer{
      background-color: transparent;
      width: 100%;
      padding: 0;
  
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  
    &__text{
      font-size: 16px;
      line-height: 16px;

      margin: 0 30px;
    }
  
    &__btn{
      padding: 10px 20px;

      font-size: 13px;
      line-height: 18px;
    }
  }
}
