.button {
  width: 100%;
  height: 44.897px;
  flex-shrink: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  background-color: rgb(33, 33, 33);
  border: 2px solid rgb(33, 33, 33);
  color: #FAFAFA;
  font-family: Gilroy, sans-serif;
  font-size: 11.776px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.635px;
  box-sizing: border-box;
}
.button:hover {
  background-color: transparent;
  color: rgb(33, 33, 33);
  border: 2px solid rgb(33, 33, 33);
  box-sizing: border-box;
  transition: 0.2s;
}

@media (max-width: 1200px) {
  .button {
    display: none;
  }
}/*# sourceMappingURL=button.css.map */