p {
  margin: 0;
}

h4 {
  margin: 0;
}

.faq {
  margin-bottom: 110px;
}

.questionBox {
  width: 100%;
  margin-bottom: 30px;
  padding: 30px;
  color: rgb(250, 250, 250);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.questionBox-text {
  display: flex;
  flex-direction: column;
}
.questionBox-text__question {
  font-family: Gilroy, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 33px;
}
.questionBox-text__answer {
  margin-top: 20px;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
}
.questionBox-text__selected {
  margin-top: 20px;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  text-decoration: none;
  color: black;
}
.questionBox-text__img {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .questionBox-text__question {
    font-family: Gilroy, sans-serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
  }
  .questionBox-text__answer {
    font-size: 25.52px;
    line-height: 30.63px;
  }
  .questionBox-text__selected {
    margin-top: 20px;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
    text-decoration: none;
    color: black;
  }
}
@media (max-width: 600px) {
  .questionBox-text__question {
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }
  .questionBox-text__answer {
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}
.questionDiv:hover {
  cursor: pointer;
}/*# sourceMappingURL=Faq.css.map */