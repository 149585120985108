@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet.css";

.vacancies{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  &__title{
    font-family: "Rubik", sans-serif;
    font-size: 59px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    
  }

  &__subTitle {
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  
    display: inline-block;
    padding: 10px;
    background-color: $beige;
  }

  &__text {
    font-family: "Gilroy", sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }

  &__btn {
    all: unset;
    padding: 20px;
    background-color: $darkGray;
    color: white;

    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
    user-select: none;
    animation: pulse 2s infinite;
    border: 2px solid $darkGray;

    &:hover{
        cursor: pointer;
        animation: none;
        background-color: transparent;
        color: $darkGray;
        transition: .2s;
    }
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(33, 33, 1, 0.4);
    box-shadow: 0 0 0 0 rgba(33, 33, 1, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(33, 33, 1, 0);
      box-shadow: 0 0 0 20px rgba(33, 33, 1, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(33, 33, 1, 0);
      box-shadow: 0 0 0 0 rgba(33, 33, 1, 0);
  }
}

.overlay {
  display: none;
  opacity:    0.7; 
  background: #000; 
  width:      100%;
  height:     100%; 
  z-index:    9;
  top:        0;  
  left:       0; 
  position:   fixed; 
}


@media (max-width: 720px){
  .vacancies{
    &__title{
      font-size: 67px;
      line-height: 59px;
    }
  
    &__subTitle {
      font-size: 23px;
      line-height: 28px;
    }
  
    &__text {
      font-size: 24px;
      line-height: 28px;
    }
  
    &__btn {
      font-size: 23px;
      line-height: 30px;
    }
  }
}

@media (max-width: 500px){
  .vacancies{
    &__title{
      font-size: 40px;
      line-height: 35px;
    }
  
    &__subTitle {
      font-size: 14px;
      line-height: 17px;
    }
  
    &__text {
      font-size: 14px;
      line-height: 17px;
    }
  
    &__btn {
      font-size: 13px;
      line-height: 18px;
    }
  }
}
