.mobile
  &-navigation
    padding-top: 30px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    &__item
      color: white
      text-decoration: none
      font-family: Gilroy, sans-serif
      font-size: 28px
      font-style: normal
      font-weight: 400
      line-height: 50px

.burger-button
  text-align: center !important
  background: #F2E0D1 !important
  margin: auto !important
  height: 3px !important
  width: 22px !important
  transform-origin: -5px !important
