@use '../../UI/colors.scss' as *;
@import '../../fonts/stylesheet.css';

.offers{

  margin-bottom: 100px;

  &__title{
    font-family: "Rubik", sans-serif;
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    text-align: center;

    margin-bottom: 40px;
  }

  &__container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr auto;
    column-gap: 30px;
    justify-items: center;
  }

}

.offerBlock {
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: space-between;

  position: relative;
  display: flex;
  flex-direction: column;

  *{
    margin-left: 20px;
    margin-right: 20px;
  }

  &_package{
    grid-row-start: 1;
    grid-row-end: 4; 

    border: 3px solid black;
  }

  &_sales{
    border: 3px solid black;
  }

  &_share{
    grid-row-start: 2;
    grid-row-end: 4;
    width: 100%;
    // border: 3px solid black;
  }

  &__title{
    font-family: "Gilroy", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;

    &_beige {
      background-color: $beige;
    }
    &_pink {
      background-color: $pink;
    }
  }

  &__subTitle{
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;


    margin-bottom: 15px;
  }

  &__footer{
    font-family: "Rubik", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;

    background-color: black;
    color: $beige;
    margin: 0px;

    position: absolute;
    bottom: 0;

    padding: 10px;
  }

  &__paragraphs{
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

    align-self: center;
    margin-bottom: 25px;
  }


  &__shareTitle{
    font-family: "Gilroy", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 0;
  }

  &__media{
    margin-left: 0;
    display: flex;
    justify-content: space-between;

    a{
      all:unset;
    }

    & img{
      margin-left: 0;
      width: 80%;

      transition: .2s;

      &:hover{
        cursor: pointer;
        transform: scale(1.1)
      }
    }
  }
}

@media(max-width: 990px){
  .offers{

    margin-bottom: 100px;
  
    &__title{
      font-size: 67px;
      font-weight: 600;
      line-height: 59px;

      margin-bottom: 40px;
    }
  
    &__container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      column-gap: 20px;
      row-gap: 20px;
      justify-items: center;
    }
  
  }
  
  .offerBlock {
    padding-top: 20px;
  
    position: relative;
    display: flex;
    flex-direction: column;
  
    *{
      margin-left: 20px;
      margin-right: 20px;
    }
  
    &_package{
      grid-row-start: 1;
      grid-row-end: 2;
      border: 3px solid black;
    }
  
    &_sales{
      grid-column: 1/3;

      border: 3px solid black;
    }
  
    &_share{
      grid-row-start: auto;
      grid-row-end: auto;
      width: 100%;
      grid-column: 1/3;
    }
  
    &__title{
      font-family: "Gilroy", sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 10px;
  
      &_beige {
        background-color: $beige;
      }
      &_pink {
        background-color: $pink;
      }
    }
  
    &__subTitle{
      font-family: "Rubik", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
  
  
      margin-bottom: 15px;
    }
  
    &__footer{
      font-size: 25px;
      font-weight: 600;
      line-height: 30px;
  
      background-color: black;
      color: $beige;
      margin: 0px;

      position: static;
  
      padding: 10px;
    }
  
    &__paragraphs{
      font-family: "Rubik", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
    }
  
  
    &__shareTitle{
      font-family: "Gilroy", sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 10px;
      margin-left: 0;
    }
  
    &__media{
      margin-left: 0;
      margin-right: 0;

      display: flex;
      justify-content: center;
      margin-top: 20px;

      a {
        text-align: center;
      }

      & img{
        margin-left: 0;
        width:60%;
      }
    }
  }
}

@media(max-width: 500px){
  .offers{

    margin-bottom: 100px;
  
    &__title{
      font-size: 40px;
      line-height: 35px;
      margin-bottom: 40px;
    }
  
    &__container{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;
      column-gap: 0;
      row-gap: 20px;
      justify-items: center;
    }
  
  }
  
  .offerBlock {
    padding-top: 20px;
  
    position: relative;
    display: flex;
    flex-direction: column;

    grid-column: 1;
  
    *{
      margin-left: 20px;
      margin-right: 20px;
    }
  
    &_package{
      grid-row: auto;
      border: 3px solid black;
    }
  
    &_sales{
      grid-column: 1/4;
      border: 3px solid black;
    }
  
    &_share{
      grid-row-start: auto;
      grid-row-end: auto;
      width: 100%;
    }
  
    &__title{
      font-size: 23px;
      line-height: 29px;

      margin-bottom: 10px;
      &_beige {
        background-color: $beige;
      }
      &_pink {
        background-color: $pink;
      }
    }
  
    &__subTitle{
      font-size: 9px;
      line-height: 11px;
      margin-bottom: 15px;
    }
  
    &__footer{
      font-size: 18px;
      line-height: 22px;

      margin: 0px;
      padding: 10px;
    }
  
    &__paragraphs{
      font-size: 14px;
      line-height: 16px;

    }
  
  
    &__shareTitle{
      font-size: 18px;
      line-height: 21px;

      margin-bottom: 10px;
      margin-left: 0;
    }
  
    &__media{
      margin-left: 0;
      margin-right: 0;
      display: flex;
      justify-content: center;
      margin-top: 5px;
  
      & img{
        margin-left: 0;
        margin-right: 0;
        width: 80%;
      }
    }
  }
}