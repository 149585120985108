.media
  width: 140px
  display: flex
  align-items: center
  justify-content: space-between
  gap: 2px

.header
  &-media
    &__img
      width: 30px
      height: 30px

@media (max-width: 1200px)
  .media
    display: none
