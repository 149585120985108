@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet.css";
$video-width: 600px;
$main-margins: 40px;


.schoolMPlus {
  &__background{
    background-color: $darkGray;
    color: white;
    padding: 30px 0;
  }

  &__title{
    // font-family: "Rubik", sans-serif;
    // font-size: 59px;
    // font-weight: 600;
    // line-height: 59px;
    // letter-spacing: 0em;
    // text-align: center;
    color: $beige;

    margin-bottom: 40px;
  }

  &__info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: $main-margins;
  }

  &__video{
    align-self: center;
    justify-self: center;
  }

  &__connection{
    display: flex;
    justify-content: space-between;
    margin-bottom: $main-margins;
  }

  &__footer{
    font-family: "Gilroy", sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;

    color: white;

    padding: 0 200px;
  }

}

.textBlock{
  font-family: "Gilroy", sans-serif;
  letter-spacing: 0em;
  text-align: left;
  &__title{
    font-size: 26px;
    font-weight: 700;
    line-height: 33px;

    margin-bottom: 20px;
  }

  &__text{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
}

.connectionBlock{
  letter-spacing: 0em;
  text-align: left;
  color: $beige;

  *{
    margin-bottom: 10px;
  }

  &__icon{
    width: 54px;
  }

  &__title{
    font-family: "Rubik", sans-serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 37px;
  }

  &__description{
    font-family: "Gilroy", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  &:hover {
    cursor:default;
  }


}

// FOR VIDEO
#video_container{
  position: relative;
  width: $video-width;
}

video{
  width: $video-width;
}

#play_button{
  position: absolute;
  left: 0; 
  right: 0; 
  top: 0; 
  bottom: 0; 

  margin: auto; 
  width: 130px;

  border-radius: 50%;
  animation: pulseBtn 2s infinite;
}

@keyframes pulseBtn {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(242, 224, 209, 0.568);
    box-shadow: 0 0 0 0 rgba(242, 224, 209, 0.568);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(242, 224, 209, 0);
      box-shadow: 0 0 0 20px rgba(242, 224, 209, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(242, 224, 209, 0);
      box-shadow: 0 0 0 0 rgba(242, 224, 209, 0);
  }
}

@media (max-width: 1200px) {
  .schoolMPlus {
  
    // &__title{
    //   font-size: 67px;
    //   line-height: 59px;

    //   margin-bottom: 40px;
    // }
  
    &__info{
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin-bottom: $main-margins;
    }
  
    &__connection{
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: $main-margins;
    }
  
    &__footer{
      font-size: 34px;
      line-height: 42px;

      padding: 0 0;
    }
  
  }
  
  .textBlock{
    &__title{
      font-size: 40px;
      line-height: 50px;
    }
  
    &__text{
      font-size: 19px;
      line-height: 23px;
    }
  }
  
  .connectionBlock{
    letter-spacing: 0em;
    text-align: left;
    color: $beige;
  
    *{
      margin-bottom: 10px;
    }
  
    &__icon{
      width: 40px;
    }
  
    &__title{
      font-size: 39px;
      line-height: 33px;
    }
  
    &__description{
      font-size: 18px;
      line-height: 22px;
    }
  
  }

  #video_container{
    width: 100%;
  }
  
  video{
    width: 100%;
  }
  
}

@media (max-width: 576px) {
  .schoolMPlus {
  
    // &__title{
    //   font-size: 40px;
    //   line-height: 35px;

    //   margin-bottom: 40px;
    // }
  
    &__connection{
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: $main-margins;
    }
  
    &__footer{
      font-size: 20px;
      line-height: 25px;
    }
  
  }
  
  .textBlock{
    &__title{
      font-size: 24px;
      line-height: 30px;
    }
  
    &__text{
      font-size: 16px;
      line-height: 19px;
    }
  }
  
  .connectionBlock{
    letter-spacing: 0em;
    text-align: left;
    color: $beige;
  
    *{
      margin-bottom: 10px;
    }
  
    &__icon{
      width: 40px;
    }
  
    &__title{
      font-size: 23px;
      line-height: 20px;
    }
  
    &__description{
      font-size: 11px;
      line-height: 13px;
    }
  
  }

  #video_container{
    width: 100%;
  }
  
  video{
    width: 100%;
  }
  
}

