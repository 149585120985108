@use "../../UI/colors.scss" as *;
@import "../../fonts/stylesheet";

.questionForm {
  display: flex;
  flex-direction: column;
  justify-items: space-between;

  &__title {
    font-family: "Rubik", sans-serif;
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;

    margin-bottom: 20px;
  }

  &__subTitle{
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;

    padding: 10px 20px;

    background-color: $beige;
    display: inline-block;
    align-self: center;
    margin-bottom: 40px;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
  }

  &__input{
    padding: 10px;
    background-color: rgba(250, 250, 250, 1);
    color: $darkGray;
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    border: 2px solid $darkGray;
    position: relative;

    &::placeholder {
      color: $darkGray;
    }
  }

  &__btn{
    all: unset;
    padding: 20px;
    background-color: $darkGray;
    color: white;
    border: 2px solid $darkGray;
    box-sizing: border-box;

    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
    user-select: none;

    &:hover{
      cursor: pointer;
      background-color: transparent;
      color: $darkGray;
      border: 2px solid $darkGray;
      box-sizing: border-box;
      transition: .2s;
    }

  }

  .react-tel-input {
    width: auto !important;
    height: auto !important;
    margin: 0 !important;

    font-family: "Gilroy", sans-serif !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 33px !important;

    .selected-flag{
      border: none !important;
    }
    
    input {
      width: 100% !important;
      height: 100% !important;
      padding: 10px !important;
      padding-left: 50px !important;
      background-color: rgba(250, 250, 250, 1);
      color: $darkGray !important;
      font-family: "Gilroy", sans-serif !important;
      font-size: 27px !important;
      font-weight: 400 !important;
      line-height: 33px !important;
      letter-spacing: 0em;
      text-align: left;
      border: 2px solid $darkGray !important;
      position: relative;

      &::placeholder {
        color: $darkGray;
      }
    }
  } 

  #forSafari {
    .questionForm__form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 80% 80%;
      gap: 20px;
    }
  }
}


@media (max-width: 1200px){
  .questionForm {
  
    &__title {
      font-size: 59px;
      line-height: 59px;
    }
  
    &__subTitle{
      font-size: 18px;
      line-height: 21px;
    }
  
    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
  
      // justify-items: center;
      gap: 50px;
    }
  
    &__input{
      font-size: 27px;
      line-height: 32px;
    }
  
    &__btn{
      font-size: 27px;
      line-height: 33px;
    }
  }
}

@media (max-width: 991px){
  .questionForm {
  
    &__title {
      font-size: 67px;
      line-height: 59px;
    }
  
    &__subTitle{
      font-size: 23px;
      line-height: 28px;
    }
  
    &__form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  
    &__input{
      font-size: 34px;
      line-height: 40px;
      padding: 20px;
    }

    .react-tel-input {
      input{
        font-size: 34px !important;
        line-height: 40px !important;
        padding: 20px !important;
        padding-left: 40px !important;
      }
    }
  
    &__btn{
      font-size: 27px;
      line-height: 33px;
    }
  }
}
@media (max-width: 500px){
  .questionForm {
  
    &__title {
      font-size: 40px;
      line-height: 35px;
    }
  
    &__subTitle{
      font-size: 14px;
      line-height: 17px;
    }
  
    &__form {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
    }
  
    &__input{
      height: auto;
      font-size: 20px;
      line-height: 24px;
    }
  
    &__btn{
      font-size: 20px;
      line-height: 34px;
    }

    .react-tel-input {
      input{
        height: auto !important;
        font-size: 20px !important;
        line-height: 24px !important;
        padding-left: 60px !important;
      }
    }
  }
}


.test {
  background-color: red !important;
  border: 2px solid green !important;
}




// @media(max-width: 1800px) {
//   // @supports (-webkit-appearance: none) {
//     #forSafari {
//       .questionForm__form {
//         display: grid;
//         grid-template-columns: 1fr 1fr 1fr;
//         grid-template-rows: 80% 80%;
//         gap: 20px;
//       }
//     }
//   // }
// }