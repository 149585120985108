.club {
	padding: 0 0 120px;
	&-box {
		border: 2.208px solid #000;
		&__top {
			background: #f2e0d1;
			display: flex;
			padding: 20px 40px;
			justify-content: center;
			align-items: center;
			text-align: center;
			& h4 {
				color: #212121;
				text-align: center;
				font-size: 36.801px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				font-family: 'Gilroy', sans-serif;
				@media (max-width: 768px) {
					font-size: 24px;
				}
			}
		}
		&__center {
			padding: 40px 72px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 128px;
			font-family: 'Rubik', sans-serif;
			@media (max-width: 1024px) {
				gap: 50px;
			}
			@media (max-width: 768px) {
				grid-template-columns: repeat(1, 1fr);
				padding: 24px 20px;
			}
			&-name {
				background: #212121;
				display: flex;
				padding: 8.832px 15.456px;
				justify-content: center;
				align-items: center;
				gap: 7.36px;
				align-self: stretch;
				color: #f2e0d1;
				font-size: 14.72px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 32px;
				@media (max-width: 768px) {
					margin-bottom: 16px;
				}
			}
			&-item {
				& p {
					color: #212121;
					font-size: 22.08px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (max-width: 768px) {
						font-size: 18px;
					}
				}
				& p + p {
					margin-top: 16px;
				}
			}
		}
		&__bottom {
			background: #bce0e2;
			/* display: flex; */
			padding: 24px 32px;
			justify-content: space-between;
			align-items: center;
			gap: 24px;
			& .container {
				@media (max-width: 768px) {
					padding: 0;
				}
			}
			@media (max-width: 768px) {
				padding: 24px 20px;
			}
			& p {
				color: #212121;
				font-family: 'Rubik', sans-serif;
				font-size: 22.08px;
				font-weight: 600;
				line-height: 166.667%;
				margin-bottom: 16px;
				max-width: 880px;
				margin: 0 auto 16px;
				text-align: center;
				@media (max-width: 768px) {
					text-align: left;
					font-size: 18px;
				}
			}
		}
	}
	& .form-title {
		margin-bottom: 20px;
		width: 100%;
		@media (max-width: 768px) {
			font-size: 18px;
			height: auto;
			padding: 16px;
		}
	}
}
