body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.title {
	font-family: 'Rubik', sans-serif;
	font-size: 70px;
	font-weight: 600;
	line-height: 80px;
	letter-spacing: 0em;
	text-align: center;

	margin-bottom: 30px;
}

.blockMargin {
	margin-bottom: 150px;
}

@media (max-width: 1300px) {
	.title {
		font-size: 59px;
		line-height: 59px;
	}
}

@media (max-width: 720px) {
	.title {
		font-size: 67px;
		line-height: 59px;
	}

	.blockMargin {
		margin-bottom: 140px;
	}
}

@media (max-width: 500px) {
	.title {
		font-size: 40px;
		line-height: 35px;
	}

	.blockMargin {
		margin-bottom: 120px;
	}
}

.App {
	overflow-x: hidden;
}
