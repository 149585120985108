.header
  display: flex
  height: 136px
  align-items: center
  justify-content: space-between

  &-firstblock
    display: flex
    justify-content: space-evenly
    width: 55%

  &-secondblock
    display: flex
    justify-content: space-evenly
    width: 45%

  &-logo
    width: 20%
    height: auto

  &-navigation
    display: flex
    align-items: center
    justify-content: space-evenly
    width: 80%

    &__item
      margin-right: 10px
      margin-left: 10px
      text-decoration: none
      color: #212121
      font-family: Gilroy, sans-serif
      font-size: 14.72px
      font-style: normal
      font-weight: 400
      line-height: 19.635px

  &-phone
    display: flex
    align-items: center
    justify-content: space-between

    &__number
      margin-left: 10px
      color: #212121
      text-align: right
      text-decoration: none
      font-family: Gilroy, sans-serif
      font-size: 14.72px
      font-style: normal
      font-weight: 500
      line-height: 18px

      &:hover
        cursor: pointer
        text-decoration: underline

@media (max-width: 1200px)
  .header
    &-navigation
      display: none

    &-firstblock
      justify-content: flex-start

    &-phone
      position: absolute
      right: 20%
      top: 55px

    &-logo
      width: 115px
      height: auto

@media (max-width: 576px)
  .header
    height: 90px

    &-logo
      width: 68px
      height: auto

    &-phone
      top: 35px
