p
  margin: 0

h4
  margin: 0

.faq
  margin-bottom: 110px
  
.questionBox
  width: 100%
  margin-bottom: 30px
  padding: 30px
  color: rgba(250, 250, 250, 1)
  display: flex
  justify-content: space-between
  align-items: center

  &-text
    display: flex
    flex-direction: column

    &__question
      font-family: Gilroy, sans-serif
      font-size: 26px
      font-weight: 700
      line-height: 33px

    &__answer
      margin-top: 20px
      font-family: Gilroy, sans-serif
      font-size: 18px
      font-weight: 400
      line-height: 22px
      width: 100%
    &__selected
      margin-top: 20px
      font-family: Gilroy, sans-serif
      font-size: 18px
      font-weight: 400
      line-height: 22px
      width: 100%
      text-decoration: none
      color: black

    &__img
      cursor: pointer


@media (max-width: 1024px)
  .questionBox

    &-text

      &__question
        font-family: Gilroy, sans-serif
        font-size: 34px
        font-weight: 700
        line-height: 42px

      &__answer
        font-size: 25.52px
        line-height: 30.63px

      &__selected
        margin-top: 20px
        font-family: Gilroy, sans-serif
        font-size: 18px
        font-weight: 400
        line-height: 22px
        width: 100%
        text-decoration: none
        color: black

@media (max-width: 600px)
  .questionBox

    &-text

      &__question
        font-family: Gilroy, sans-serif
        font-size: 20px
        font-weight: 700
        line-height: 25px

      &__answer
        font-family: Gilroy, sans-serif
        font-size: 14px
        font-weight: 400
        line-height: 17px



.questionDiv
  &:hover 
    cursor: pointer