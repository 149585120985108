@use "../../../UI/colors.scss" as *;
@import "../../../fonts/stylesheet.css";

.popup {
  background-color: white;
  z-index: 10;
  padding: 20px 100px;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  position: fixed;
  top: 0;
  transform: translate(0%, 5%);


  animation: slideIn .5s linear;

  &__titleContainer{
    position: relative;
  }

  &__title {
    font-family: "Rubik", sans-serif;
    font-size: 59px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
  }

  &__subTitle{
    margin-bottom: 30px;
  }

  &__cross {
    position: absolute;
    right: -200px;
    top: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}

.iconContainer{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  &__block{
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__text{
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
  }
  margin-bottom: 30px;
}

.formContainer {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;

  &__input {
    padding: 10px;
    background-color: rgba(250, 250, 250, 1);
    color: $darkGray;

    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;

    border: 2px solid $darkGray;
    position: relative;

    &::placeholder {
      color: $darkGray;
    }
    
    &_file{
      display: flex;
      align-items: center;
    }
  }

  &__clip {
    position: absolute;
    right: 10px;
  }
}

input[type="file"] {
  display: none;
}

@keyframes slideIn {
  0% {
    transform: translate(0%, -100%);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translate(0%, 5%);
    animation-timing-function: ease-in;
  }
}



@media (max-width: 1400px){
  .popup {
    // max-width: 80%;
    // max-height: 80%;
    overflow-y: auto;

    &__title {
      margin-bottom: 30px;
    }

    &__subTitle{
      display: block;
      margin: 0 auto;
      margin-right: 20%;
      margin-left: 20%;
      margin-bottom: 30px;
    }
  }


  
  .formContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width:1200px){
  .popup {
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;

    &__cross {
      position: absolute;
      right: -50px;
      top: 20px;
    }
  }
  
  .iconContainer{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  
    &__block{
      display: flex;
      gap: 20px;
      align-items: center;
    }
  
    &__text{
      font-family: "Gilroy", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
    }
    margin-bottom: 30px;
  }
  
  .formContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;

    overflow-y: scroll;
    height: 100%;
  }
}

@media (max-width: 600px){
  .popup {
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    padding: 10px;

    &__titleContainer{
      display: flex;
    }

    &__title {
      font-size: 40px;
      line-height: 40px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 19px;

      margin-right: 10%;
      margin-left: 10%;
    }

    &__cross {
      position: static;
      align-self: flex-start;
      width: 10%;
    }
  }
  
  .iconContainer{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  
    &__block{
      display: flex;
      gap: 20px;
      align-items: center;
    }
  
    &__text{
      font-size: 14px;
      line-height: 17px;
    }

    &__img{
      width: 20%;
    }

    margin-bottom: 30px;
  }

  .formContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;

    overflow-y: scroll;
    height: 100%;

    &__input {
      padding: 10px;
      background-color: rgba(250, 250, 250, 1);
      color: $darkGray;

      font-size: 20px;
      line-height: 24px;
  
      &::placeholder {
        color: $darkGray;
      }
      
      &_file{
        display: flex;
        align-items: center;
      }
    }
  }
}