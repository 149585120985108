@use '../../../UI/colors.scss' as *;
@import '../../../fonts/stylesheet.css';

.subject{
  position: relative;
  padding: 40px;
  padding-bottom: 0px;
  user-select: none;

  min-width: 100%;

  &__title{
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 60px;
    line-height: 50px;
  }

  &__subTitle{
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 15px;
    padding: 0 50px;

    margin-top: 15px;
  }

  &__footer{
    font-family: "Gilroy", sans-serif;
    font-size: 38px;
    font-weight: 700;
    text-align: center;

    padding: 15px 0;

    color: white;
    background-color: $darkGray;
    position: absolute;
    left: 0;
    right: 0;

    &:hover {
      background-color: transparent;
      cursor: pointer;
      color: $darkGray;
      border: 2px solid $darkGray;
      box-sizing: border-box;
      transition: .2s;
    }
  }

  &__variants {
    margin-bottom: 50px;
  }


  &_math{
    background-color: $beige;
  }

  &_eng{
    background-color: $pink;
  }

  &_ukr{
    background-color: $blue;
  }
}

.variants {

  margin-top: 20px;

  &__title{
    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  &__btnContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }

  &__btn{
    font-family: Rubik;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;  
    text-align: center;

    padding: 10px 0;
    background-color: white;
    color: $darkGray;

    &_active{
      background-color: $darkGray;
      color: white;
    }

    &_disabled {
      background-color: $lightGray;
      color: $gray;
    }
    
    transition: 0.25s;
  
    &:hover,
    &:focus { 
      border-color: var(--hover);
      background-color: $darkGray;
      color: #fff;
    }
  }

  &__price{
    color: transparent;

    font-family: "Rubik", sans-serif;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    font-size: 15px;
    line-height: 17px;


    margin-top: 5px;
  }
}

@media (max-width: 1400px){
  .subject{
    padding: 40px;
    padding-bottom: 0px;
  
    &__title{
      font-size: 44px;
      line-height: 37px;
    }
  
    &__subTitle{
      font-size: 15px;
      line-height: 17px;
      padding: 0 20px;
    }
  
    &__footer {
      padding: 15px 0;
      font-size: 28px;
      line-height: 44px;

      &:hover {
        background-color: transparent;
        cursor: pointer;
        color: $darkGray;
        border: 0px solid $darkGray;
        box-sizing: border-box;
        transition: .2s;
      }
    }


  }
  
  .variants {
  
    margin-top: 20px;
  
    &__title{
      font-size: 15px;
      line-height: 18px;
    }
  
    &__btnContainer{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 20px;
    }
  
    &__btn{
      font-size: 15px;
      line-height: 22px;
    }
  }
  
}

@media (max-width: 1400px){
  .subject{
    padding: 20px;
    padding-bottom: 0px;
  
    &__title{
      font-size: 38px;
      line-height: 37px;
    }
  
    &__subTitle{
      font-size: 15px;
      line-height: 17px;
      padding: 0 0px;
    }
  }
  
  .variants {
    &__title{
      font-size: 15px;
      line-height: 18px;
    }

    &__btn{
      font-size: 13px;
    }
  }
  
}

@media (max-width: 990px){
  .subject{
    position: relative;
    padding: 40px;
    padding-bottom: 0px;
  
    min-width: auto;
    justify-self: center;

    margin-bottom: 100px;
  }
  .variants {
    &__btn{
      font-size: 20px;
      line-height: 30px;  
      padding: 10px 0;
    }
  }
}

@media (max-width: 500px){
  .subject{
    padding: 10px;
    &__title{
      font-size: 40px;
      line-height: 33px;
      margin-bottom: 10px;
    }
  
    &__subTitle{
      padding: 0 10px;
      font-size: 13px;
      line-height: 16px;

    }
  
    &__footer{
      font-size: 25px;
      line-height: 40px;
    }
  
    &__variants {
      margin-bottom: 50px;
    }
  }
  
  .variants {
    &__title{
      font-size: 13px;
      line-height: 16px;
    }
  
    &__btn{
      font-size: 15px;
      line-height: 20px;
    }
  }
  
}